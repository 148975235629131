globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"499f14428e5f303096d1b7f77f87cb769a4103a8"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import { initSentry } from "./sentry.config"
import {
  browserTracingIntegration,
  browserProfilingIntegration,
} from "@sentry/nextjs"

initSentry({
  integrations: [browserTracingIntegration(), browserProfilingIntegration()],
  tracePropagationTargets: ["paragraph.xyz/api", "api.paragraph.xyz"],
})
